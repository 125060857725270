import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Halagig = () => {
  const headingbanner = {
    title: `Hala Gig`,
    content: `A Consumer Experience Platform`,
  };

  const data = {
    images: ["hala-gig.webp", "hala-gig-2.webp"],
    firstAlt: "Mobile UI Design To Collect Customer's Response",
    firstTitle: "Feedback Collection Screen",
    secondAlt: "UI Design Of Agent's Account",
    secondTitle: "Agent's Account",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "React Development"],
      },
      {
        title: "Platform",
        text: ["Web Application", "Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `They have to gather feedback and data from all sizes need to gather feedback and data from their customers and stakeholders to make informed decisions and improve their products or services.`,
          `However, creating surveys and collecting responses manually can be a time consuming and inefficient process.`,
          `It can also be challenging to organize and analyze the data effectively, especially when dealing with large volumes of responses.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `The platform will simplify the survey creation process making it easy for the client to gather valuable insights from the stakeholders in a cost-effective and efficient manner. `,
          `It will effortlessly gather the data from the end-users in the field and enable clients to create surveys, assign them to the field agent and streamline the entire data collection process.`,
        ],
      },
    ],
    image: ["hala-gig-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: "Create Project",
    para: [
      `By creating survey project, agent will be responsible for gathering responses from individuals in the assigned area.`,
    ],
    content: {
      imageAlt:
        "UI UX design to create project and define project requirements",
      imageTitle: "Create Project",
      image: ["hala-gig-projectone.webp"],
      text: `The task will involve approaching potential respondents, introducing our survey, and collecting their responses via our mobile app. This will contribute to provide valuable insights and help them in their research. `,
    },
    content2: {
      imageAlt:
        "UI Screen to add live link in the survey and connect other apps",
      imageTitle: "Add Link",
      image: ["hala-gig-projectone-2.webp"],
      text: `The agents can also connect different application with their mobile app to facilitate smooth collection of responses and for easy accessibility.`,
    },
  };

  const projectData2 = {
    title: `Assigned Surveys to Agents`,
    para: [
      `Company's assigned surveys will be available on Hala Gig Mobile app and will diligently collect responses while also ensuring the quality of data collected.`,
    ],
    content: {
      image: ["hala-gig-projecttwo.webp"],
      imageAlt: "Mobile UI Design To Collect Customer's Response",
      imageTitle: "Feedback Collection Screen",
    },
  };

  const projectData3 = {
    title: "Ensuring High-Quality Survey Responses",
    para: [
      `A web application for companies to create and manage surveys, and a mobile application for field agents to collect responses from potential respondents in the field. `,
      `This seamless integration allows for efficient survey data collection and analysis. It also ensures that high-quality data is obtained for valuable insights and decision-making.`,
    ],
    content: {
      image: [
        "hala-gig-projectthree.webp",
        "hala-gig-projectthree-2.webp",
        "hala-gig-projectthree-3.webp",
        "hala-gig-projectthree-4.webp",
        "hala-gig-projectthree-5.webp",
        "hala-gig-projectthree-6.webp",
        "hala-gig-projectthree-7.webp",
      ],
      firstAlt: "Login Screen to maintain user privacy",
      firstTitle: "Login Screen",
      secondAlt: "Dashboard to understand project wise responses",
      secondTitle: "Dashboard",
      thirdAlt: "UI UX screen of specific company details",
      thirdTitle: "Company Details",
      fourthAlt: "Billing & Invoices details and status",
      fourthTitle: "Billing & Invoices",
      fifthAlt: "UI screen to check each project feedback status",
      fifthTitle: "Project Feedback Status",
      sixthAlt: "Check task details and recent transactions",
      sixthTitle: "Task Details",
      seventhAlt: "Specific Agent's Details",
      seventhTitle: "Agents Details",
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our survey data collection solution has proven to be a valuable tool for companies seeking to gather reliable and actionable data. `,
        `By providing a user-friendly web application for survey creation and management, and a mobile application for field agents to collect responses in the field, we have streamlined the survey data collection process and ensured high-quality data.`,
        `This has resulted in valuable insights and improved decision-making for our client. Our solution has proven to be flexible, scalable, and effective, making it a top choice for companies across industries.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={4} />
      </div>
    </Layout>
  );
};

export default Halagig;

export const Head = () => (
  <Seo
    title="HalaGig- Consumer Experience Platform Case Study | Octet"
    description="We conducted User Research, offered UI UX design and Reactjs Services with mobile-first approach for customer experience platform, a UAE based company."
  />
);
